<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="users"
      :roles="roles"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-alert dense v-model="alert" type="success">
          Changes saved successfully.
        </v-alert>
        <v-toolbar flat>
          <v-toolbar-title>Users</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New User
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Name"
                        :error-messages="errors.name"
                        error-count="3"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                        type="email"
                        :error-messages="errors.email"
                        error-count="3"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[passwordMinCharactersRule]"
                        :type="showPassword ? 'text' : 'password'"
                        hint="At least 8 characters"
                        label="Password"
                        counter
                        :error-messages="errors.password"
                        error-count="3"
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedItem.password_confirmation"
                        label="Confirm password"
                        :append-icon="
                          showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :type="showPasswordConfirmation ? 'text' : 'password'"
                        name="input-10-2"
                        class="input-group--focused"
                        :error-messages="errors.password_confirmation"
                        @click:append="
                          showPasswordConfirmation = !showPasswordConfirmation
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.roles"
                        label="Roles"
                        :items="roles"
                        multiple
                        chips
                        :error-messages="errors.roles"
                        error-count="3"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4"> </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <ConfirmDelete
            v-if="deleteItem"
            :item="deleteItem"
            :delete-action="deleteAction"
            @close="deleteItem = null"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.roles`]="{ item }">
        <div v-for="(role, index) in item.roles" :key="index" class="role-chip">
          <v-chip small class="ma-1">
            {{ role }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem = item"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_USERS } from "@/store/users.module";
import { CREATE_USER } from "@/store/users.module";
import { UPDATE_USER } from "@/store/users.module";
import { DELETE_USER } from "@/store/users.module";
import { GET_ROLES } from "@/store/users.module";
import ConfirmDelete from "@/components/modals/ConfirmDelete";

export default {
  name: "List",
  components: { ConfirmDelete },
  mounted() {
    this.fetchUsers();
    this.fetchRoles();
  },
  data: () => ({
    alert: false,
    dialog: false,
    deleteAction: DELETE_USER,
    deleteItem: null,
    headers: [
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Roles",
        value: "roles",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    editedIndex: -1,
    editedItem: {
      id: "",
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      roles: [],
    },
    defaultItem: {
      id: "",
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      roles: [],
    },
    showPassword: false,
    showPasswordConfirmation: false,
  }),
  computed: {
    ...mapState({
      users: (state) => state.users.users,
      errors: (state) => state.users.errors,
      roles: (state) => state.users.roles,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
    passwordMinCharactersRule() {
      return () =>
        typeof this.editedItem.password === "undefined" ||
        this.editedItem.password.length === 0 ||
        this.editedItem.password.length >= 8 ||
        "Min 8 characters";
    },
  },
  watch: {
    alert(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.alert = false;
        }, 3000);
      }
    },
    dialog(val) {
      val || this.close();
    },
  },

  created() {},
  methods: {
    fetchUsers() {
      this.$store.dispatch(GET_USERS);
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      Object.keys(this.errors).forEach((key) => {
        delete this.errors[key];
      });
    },

    displayAlert() {
      this.alert = true;
    },

    save() {
      let action = CREATE_USER;

      if (this.editedIndex > -1) {
        action = UPDATE_USER;
      }

      this.$store
        .dispatch(action, this.editedItem)
        .then(this.close)
        .then(this.displayAlert);
    },

    fetchRoles() {
      this.$store.dispatch(GET_ROLES);
    },
  },
};
</script>

<style scoped>
.role-chip {
  display: inline-block;
}
</style>
